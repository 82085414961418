import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
// Import Shared Components
import { Button } from 'shared-components';

const VCButton = ({ buttonText, buttonFor, page, ...props }) => (
  <Button
    {...props}
  >
    <FormattedMessage
      id={`${page}-${buttonFor}-button`}
      description={`Button for ${buttonFor}`}
      defaultMessage={buttonText}
    />
  </Button>
);

VCButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  buttonFor: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired
};

export default VCButton;
