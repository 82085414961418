import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Import Component Styles
import Subtitle from './styles';

const CollectionsSubtitle = ({ subtitle, subtitleFor, page, ...props }) => (
  <Subtitle {...props}>
    <FormattedMessage
      id={`${page}-${subtitleFor}-subtitle`}
      description={`Subtitle for ${subtitle}`}
      defaultMessage={`${subtitle}`}
    />
  </Subtitle>
);

CollectionsSubtitle.propTypes = {
  page: PropTypes.string.isRequired,
  subtitleFor: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default CollectionsSubtitle;
