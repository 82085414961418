//  Error logging for Cardholder Portal Application
import SumoLogger from 'sumo-logger';
import {
  generateErrorLogger,
  consoleErrorTransport,
  sumoLogicErrorTransport,
} from 'shared-components/src/utils/errors';
import { PRODUCTION } from 'shared-components/src/constants/nodeEnv';


// Construct Application Error Logger
// Create Error Logger and export to rest of application
let logger;

// During Build Time we will conditionally use different errorLogTransports
// depending on environment
if (process.env.NODE_ENV === PRODUCTION) {
  const sumoLogger = new SumoLogger({
    endpoint: process.env.REACT_APP_SUMO_LOGIC_ENDPOINT
  });
  logger = generateErrorLogger('Collections', sumoLogicErrorTransport(sumoLogger));
} else {
  logger = generateErrorLogger('Collections', consoleErrorTransport);
}

const errorLogger = logger;

export { errorLogger };
