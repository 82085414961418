import React from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Alert } from 'shared-components';

const AlertWrapper = ({ alert, alertFor, page, ...props }) => (
  <Alert {...props}>{alert}</Alert>
);

AlertWrapper.propTypes = {
  alert: PropTypes.string.isRequired,
  alertFor: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired
};

export default AlertWrapper;