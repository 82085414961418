export const redeemEn = {
  'redeem-main-title': 'Make a payment!',
  'redeem-main-text': 'Please enter your Visa Debit or MasterCard Debit card information and a payment amount.',
  'redeem-form-error-alert': 'There seems to be a problem processing your payment. Please note the transaciton ID if present and reach out to your collections company for assistance, contact details provided in the redemption email.',
  'redeem-faq-link': 'FAQ',
  'redeem-chat-link': 'Chat With Us',
  'redeem-accountInfo-title': 'Account Information',
  'redeem-cardInformation-legend': 'Card Information',
  'redeem-firstName-label': 'Cardholder First Name',
  'redeem-lastName-label': 'Cardholder Last Name',
  'redeem-postalCode-label': 'Postal Code',
  'redeem-amount-label': 'Payment Amount ($)',
  'redeem-cardNumber-label': '16 Digit Card Number',
  'redeem-exp-label': 'Expiry Date (MMYY)',
  'redeem-cvv-label': '3 Digit CVV',
  'redeem-submit-button': 'Submit',
  'redeem-invalidLink-title': 'Unable to locate payment information',
  'redeem-invalidLink-text': 'We are sorry we are unable to locate the Collection you are looking for, please contact the sender of your Collection notice.',
  'redeem-expiredLink-title': 'This payment link has expired',
  'redeem-expiredLink-text': 'Please request a new payment link from your Account Manager or contact us using the links below',
  'redeem-paymentmessage-success-title': 'Your payment was approved!',
  'redeem-paymentmessage-success-text': 'Thanks for making a payment, we will use reasonable efforts to post your payment to your account within 48 hours, excluding holidays.',
  'redeem-paymentmessage-section1-subtitle': 'Account Information',
  'redeem-paymentmessage-accountInfo-item1': 'First Name:',
  'redeem-paymentmessage-accountInfo-item2': 'Last Name:',
  'redeem-paymentmessage-accountInfo-item3': 'Email:',
  'redeem-paymentmessage-section2-subtitle': 'Payment Information',
  'redeem-paymentmessage-paymentInfo-item1': 'Cardholder First Name:',
  'redeem-paymentmessage-paymentInfo-item2': 'Cardholder Last Name:',
  'redeem-paymentmessage-paymentInfo-item3': 'Card Last 4 Digits:',
  'redeem-paymentmessage-paymentInfo-item4': 'Paid Amount:',
  'redeem-paymentmessage-transaction-id': 'Transaction Reference Number: {transactionID}',
  'redeem-paymentmessage-failure-title': 'Your payment was declined',
  'redeem-paymentmessage-failure-text': 'Please try again when you’re ready. Remember to double check your card information is accurate before submitting. If you continue to have issues please contact your card issuer',
  'redeem-paymentmessage-failure-button': 'Try Again',
};

export const redeemFr = {
  'redeem-main-title': 'Make a payment!',
  'redeem-main-text': 'Please enter your Visa Debit or MasterCard Debit card information and a payment amount.',
  'redeem-form-error-alert': 'There seems to be a problem processing your payment. Please note the transaciton ID if present and reach out to your collections company for assistance, contact details provided in the redemption email.',
  'redeem-faq-link': 'FAQ',
  'redeem-chat-link': 'Chat With Us',
  'redeem-accountInfo-title': 'Account Information',
  'redeem-cardInformation-legend': 'Card Information',
  'redeem-firstName-label': 'Cardholder First Name',
  'redeem-lastName-label': 'Cardholder Last Name',
  'redeem-postalCode-label': 'Postal Code',
  'redeem-amount-label': 'Payment Amount ($)',
  'redeem-cardNumber-label': '16 Digit Card Number',
  'redeem-exp-label': 'Expiry Date (MMYY)',
  'redeem-cvv-label': '3 Digit CVV',
  'redeem-submit-button': 'Submit',
  'redeem-invalidLink-title': 'Unable to locate payment information',
  'redeem-invalidLink-text': 'We are sorry we are unable to locate the Collection you are looking for, please contact the sender of your Collection notice.',
  'redeem-expiredLink-title': 'This payment link has expired',
  'redeem-expiredLink-text': 'Please request a new payment link from your Account Manager or contact us using the links below',
  'redeem-paymentmessage-success-title': 'Your payment was approved!',
  'redeem-paymentmessage-success-text': 'Thanks for making a payment, we will use reasonable efforts to post your payment to your account within 48 hours, excluding holidays.',
  'redeem-paymentmessage-section1-subtitle': 'Account Information',
  'redeem-paymentmessage-accountInfo-item1': 'First Name:',
  'redeem-paymentmessage-accountInfo-item2': 'Last Name:',
  'redeem-paymentmessage-accountInfo-item3': 'Email:',
  'redeem-paymentmessage-section2-subtitle': 'Payment Information',
  'redeem-paymentmessage-paymentInfo-item1': 'Cardholder First Name:',
  'redeem-paymentmessage-paymentInfo-item2': 'Cardholder Last Name:',
  'redeem-paymentmessage-paymentInfo-item3': 'Card Last 4 Digits:',
  'redeem-paymentmessage-paymentInfo-item4': 'Paid Amount:',
  'redeem-paymentmessage-transaction-id': 'Transaction Reference Number: {transactionID}',
  'redeem-paymentmessage-failure-title': 'Your payment was declined',
  'redeem-paymentmessage-failure-text': 'Please try again when you’re ready. Remember to double check your card information is accurate before submitting. If you continue to have issues please contact your card issuer',
  'redeem-paymentmessage-failure-button': 'Try Again',
};
