import React from 'react';
import PropTypes from 'prop-types';
// Import Components
import Title from '../../../../components/Title';
import Text from '../../../../components/Text';
import ListItem from '../../../../components/ListItem';
import Button from '../../../../components/Button';
import Subtitle from '../../../../components/Subtitle';
import SuccessCheck from './SuccessCheck';
import DeclineX from './DeclineX';
// Import Translations
import { redeemEn } from '../../../../i18n/redeem';
// Import styles
import { RedeemMessage, Section, SectionWrapper, DetailsList } from './styles';

const PaymentMessage = ({
  status,
  txnId,
  firstName,
  lastName,
  email,
  cardholderFirstName,
  cardholderLastName,
  paidAmount,
  cardNumberLastFour,
  successfulRedemptionMessage,
}) => {

  const transactionID = String(txnId);

  switch (status) {
  case 'approved':
  case 'settled':
    return (
      <RedeemMessage>
        <SuccessCheck />
        <div>
          <Title
            title={redeemEn['redeem-paymentmessage-success-title']}
            titleFor="paymentmessage"
            page="redeem"
          />
          <Text
            text={
              successfulRedemptionMessage ||
                redeemEn['redeem-paymentmessage-success-text']
            }
            textFor="paymentmessage"
            page="redeem"
          />
        </div>
        <Text
          text={redeemEn['redeem-paymentmessage-transaction-id']}
          textFor="paymentmessage"
          page="redeem"
          values={{
            transactionID,
          }}
        />
        <SectionWrapper>
          <Section>
            <Subtitle
              subtitle={redeemEn['redeem-paymentmessage-section1-subtitle']}
              subtitleFor="paymentmessage"
              page="redeem"
            />
            <DetailsList>
              <ListItem
                list={redeemEn['redeem-paymentmessage-accountInfo-item1']}
                itemNum="1"
                listFor="paymentmessage"
                page="redeem"
                value={firstName}
              />
              <ListItem
                list={redeemEn['redeem-paymentmessage-accountInfo-item2']}
                itemNum="2"
                listFor="paymentmessage"
                page="redeem"
                value={lastName}
              />
              <ListItem
                list={redeemEn['redeem-paymentmessage-accountInfo-item3']}
                itemNum="3"
                listFor="paymentmessage"
                page="redeem"
                value={email}
              />
            </DetailsList>
          </Section>
          <Section>
            <Subtitle
              subtitle={redeemEn['redeem-paymentmessage-section2-subtitle']}
              subtitleFor="paymentmessage"
              page="redeem"
            />
            <DetailsList>
              <ListItem
                list={redeemEn['redeem-paymentmessage-paymentInfo-item1']}
                itemNum="1"
                listFor="paymentmessage"
                page="redeem"
                value={cardholderFirstName}
              />
              <ListItem
                list={redeemEn['redeem-paymentmessage-paymentInfo-item2']}
                itemNum="2"
                listFor="paymentmessage"
                page="redeem"
                value={cardholderLastName}
              />
              <ListItem
                list={redeemEn['redeem-paymentmessage-paymentInfo-item3']}
                itemNum="3"
                listFor="paymentmessage"
                page="redeem"
                value={cardNumberLastFour}
              />
              <ListItem
                list={redeemEn['redeem-paymentmessage-paymentInfo-item4']}
                itemNum="4"
                listFor="paymentmessage"
                page="redeem"
                value={`$${paidAmount}`}
              />
            </DetailsList>
          </Section>
        </SectionWrapper>
      </RedeemMessage>
    );

  case 'declined':
  case 'error':
    return (
      <RedeemMessage>
        <DeclineX />
        <Title
          title={redeemEn['redeem-paymentmessage-failure-title']}
          titleFor="paymentmessage"
          page="redeem"
        />
        <Text
          text={redeemEn['redeem-paymentmessage-failure-text']}
          textFor="paymentmessage"
          page="redeem"
        />
        <Button
          onClick={() => window.location.reload()}
          size="large"
          type="primary"
          buttonText={redeemEn['redeem-paymentmessage-failure-button']}
          buttonFor="paymentmessage"
          page="redeem"
        />
      </RedeemMessage>
    );

  default:
    return null;
  }
};

PaymentMessage.propTypes = {
  status: PropTypes.string.isRequired,
  txnId: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  cardholderFirstName: PropTypes.string.isRequired,
  cardholderLastName: PropTypes.string.isRequired,
  paidAmount: PropTypes.string.isRequired,
  cardNumberLastFour: PropTypes.string.isRequired,
  successfulRedemptionMessage: PropTypes.string,
};

PaymentMessage.defaultProps = {
  successfulRedemptionMessage: null,
};

export default PaymentMessage;
