import React, { useState } from 'react';
import PropTypes from 'prop-types';
// Import Shared Components
import { Loader, ClientErrorComponent } from 'shared-components';
import useTellerApi from 'shared-components/src/hooks/useTellerApi';
import { parseAmountToCents } from 'shared-components/src/utils/currency';
// Import Global Components
import Main from '../../components/Main';
// Import Layouts
import MainLayout from '../../layouts/Main';
// Import Local Components
import Form from './components/Form';
import InvalidLink from './components/InvalidLink';
import ExpiredLink from './components/ExpiredLink';
import PaymentMessage from './components/PaymentMessage';
import AccountInfo from './components/AccountInfo';
// Import Translations
import { redeemEn } from '../../i18n/redeem';

const sendTransactionRequest = async (collectionId, saleData) => {
  const res = await fetch(
    `/api/v1/collections_portal/payment/${collectionId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify(saleData),
    }
  );

  const PaymentData = await res.json();

  // If an error occured when getting the sending payment info, we can display an error message
  if (PaymentData && PaymentData.error) {
    throw new Error('There was an error processing your payment.');
  }

  return PaymentData.data;
};

const Redeem = ({
  history,
  match: { params },
  setChatURL,
  setFaqURL,
  setTollFreePhone,
  setTermsAndConditions,
  ...props
}) => {
  const [error, setError] = useState(null);
  // Collection information
  const [amount, setAmount] = useState(null);
  const [collectionID, setCollectionID] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  // Company configurations
  const [
    successfulRedemptionMessage,
    setSuccessfulRedemptionMessage,
  ] = useState(null);
  // States when payment is processed
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState(null);
  // NOTE: This is the amount actually paid by the user
  const [paidAmount, setPaidAmount] = useState(null);
  const [cardholderFirstName, setCardholderFirstName] = useState(null);
  const [cardholderLastName, setCardholderLastName] = useState(null);
  const [cardNumberLastFour, setCardNumberLastFour] = useState(null);

  const { redemptionToken } = params;
  // Validate URL redemption token with Collections application
  const [{ data: validateTokenData, isLoading, isError }] = useTellerApi(
    `/api/v1/collections_portal/validate/${redemptionToken}`,
    null
  );
  // Handle submission of payment information
  const handleFormSubmission = async paymentData => {
    
    setPaymentProcessing(true);
    
    setPaidAmount(paymentData.amount);
    setCardNumberLastFour(paymentData.cardNumber.slice(-4));
    setCardholderFirstName(paymentData.firstName);
    setCardholderLastName(paymentData.lastName);

    try {
      const saleData = {
        amount: parseAmountToCents(paymentData.amount),
        postal_code: paymentData.postalCode,
        card_number: paymentData.cardNumber,
        cardholder_first_name: paymentData.firstName,
        cardholder_last_name: paymentData.lastName,
        cvv: paymentData.cvv,
        expiry_month: paymentData.exp.substring(0, 2),
        expiry_year: '20'.concat(paymentData.exp.substring(2)),
      };
      
      const transactionResponse = await sendTransactionRequest(collectionID, saleData);
      setPaymentProcessing(false);
      setPaymentResponse(transactionResponse);
    } catch (e) {
      setPaymentProcessing(false);
      setError(redeemEn['redeem-form-error-alert']);
    }
  };

  if (isLoading || paymentProcessing) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  if (isError) {
    return (
      <MainLayout>
        <ClientErrorComponent />
      </MainLayout>
    );
  }

  // If the response contains an error then we can display the correct message to the user
  if (validateTokenData && validateTokenData.error) {
    switch (validateTokenData.error) {
    // The Redemption Token is verified but the Collection has expired
    case 'Collection has expired':
      return <ExpiredLink />;
    // The Redemption Token in the URL could not be verified
    default:
      return <InvalidLink />;
    }
  }

  // Prevent gratuitous re-renders every time we render component
  if (validateTokenData && validateTokenData.data && !amount) {
    setAmount(validateTokenData.data.amount);
    setCollectionID(validateTokenData.data.id);
    setFirstName(validateTokenData.data.first_name);
    setLastName(validateTokenData.data.last_name);
    setEmail(validateTokenData.data.email);
    setChatURL(validateTokenData.data.chat_url);
    setFaqURL(validateTokenData.data.faq_url);
    setSuccessfulRedemptionMessage(
      validateTokenData.data.successful_redemption_message
    );
    setTermsAndConditions(validateTokenData.data.terms_and_conditions);
    setTollFreePhone(validateTokenData.data.toll_free_phone);
  }

  if (paymentResponse) {
    return (
      <PaymentMessage
        status={paymentResponse.transaction.status}
        txnId={paymentResponse.transaction.processor_id}
        firstName={firstName}
        lastName={lastName}
        email={email}
        cardholderFirstName={cardholderFirstName}
        cardholderLastName={cardholderLastName}
        paidAmount={paidAmount}
        successfulRedemptionMessage={successfulRedemptionMessage}
        cardNumberLastFour={cardNumberLastFour}
      />
    );
  }

  return (
    <Main
      title={redeemEn['redeem-main-title']}
      subTitle={redeemEn['redeem-main-text']}
      page="redeem"
      sectionOne={
        <AccountInfo firstName={firstName} lastName={lastName} email={email} />
      }
      sectionTwo={(
        <Form
          handleFormSubmission={handleFormSubmission}
          collectionAmount={amount / 100}
          token={redemptionToken}
          setError={setError}
        />
      )}
      error={error}
      {...props}
    />
  );
};

Redeem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  match: PropTypes.object.isRequired,
  // redemptionToken: PropTypes.string.isRequired
  setChatURL: PropTypes.func.isRequired,
  setTollFreePhone: PropTypes.func.isRequired,
  setFaqURL: PropTypes.func.isRequired,
  setTermsAndConditions: PropTypes.func.isRequired,
};

export default Redeem;
