import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Import Local Components
import Cards from '../Cards';
// Import Component Styles
import { Info, AccountInfoTitle } from './styles';
// Import Translations
import { redeemEn } from '../../../../i18n/redeem';

const AccountInfo = ({ firstName, lastName, email }) => (
  <Info>
    <div>
      <dl>
        <AccountInfoTitle>
          <FormattedMessage
            id="redeem-accountInfo-title"
            description="Label for Account Information"
            defaultMessage={redeemEn['redeem-accountInfo-title']}
          />
        </AccountInfoTitle>
        <dd>
          {firstName} {lastName}
        </dd>
        <dd>{email}</dd>
      </dl>
    </div>
    <div>
      <Cards />
    </div>
  </Info>
);

AccountInfo.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default AccountInfo;