import styled from 'styled-components';

export const HelpSection = styled.div`
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  color: white;

  & a {
    color: white;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export const HelpItems = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 30%;

  & > *:not(:last-child) {
    margin-left: 15px;
  }
`;
