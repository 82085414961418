import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
  position: relative;
  width: 100%;
  flex: 1;
  background: ${({ theme }) => theme.brand.bodyBorder};
  box-shadow: 0px 3px 6px #00000054;
  padding: 100px;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 20px;
  left: 20px;
  height: 60px;
  max-width: 120px;
`;

export const Logo = styled.img`
  max-width: 100%;
`;

export const Main = styled.main`
  display: flex
  flex-direction: column-reverse;
  flex: 1;
  align-items: stretch;
  align-content: stretch;
  min-height: 500px;

  ${({ theme }) => theme.media.desktopUp`
    flex-direction: row;
  `}
`;
