import React from 'react';
import { withTheme } from 'styled-components';
import { Logo, LogoWrapper } from 'shared-components';

import HelpSection from '../HelpSection';
// Import Component Styles
import { Body, Main } from './styles';

export default withTheme(
  ({ theme, chatURL, faqURL, tollFreePhone, children }) => (
    <Body>
      <LogoWrapper>
        <Logo src={theme.brand.logo} />
      </LogoWrapper>
      <Main>{children}</Main>
      {(chatURL || faqURL || tollFreePhone) && (
        <HelpSection
          chatURL={chatURL}
          faqURL={faqURL}
          tollFreePhone={tollFreePhone}
        />
      )}
    </Body>
  )
);
