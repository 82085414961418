import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Import styles
import { Legend } from './styles';

const CollectionsLegend = ({ legendFor, legend, page }) => (
  <Legend>
    <FormattedMessage
      id={`${page}-${legendFor}-legend`}
      description={`Label for ${legend}`}
      defaultMessage={`${legend}`}
    />
  </Legend>
);

CollectionsLegend.propTypes = {
  page: PropTypes.string.isRequired,
  legendFor: PropTypes.string.isRequired,
  legend: PropTypes.string.isRequired

};

export default CollectionsLegend;
