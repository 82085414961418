import React, { useState, useEffect } from 'react';
import { DeclineX } from './styles';

export default () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setTimeout(
      () =>
        requestAnimationFrame(() => {
          setAnimate(true);
        }),
      500
    );
  }, []);

  return (
    <DeclineX>
      {animate && (
        <svg
          className="checkmark"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
        >
          <circle
            className="checkmark__circle"
            cx="26"
            cy="26"
            r="25"
            fill="none"
          />
          <path
            className="checkmark__check"
            fill="none"
            d="M16 16 36 36 M36 16 16 36"
          />
        </svg>
      )}
    </DeclineX>
  );
};
