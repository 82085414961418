import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
// Import Component Styles
import Title from './styles';

const CollectionsTitle = ({ title, titleFor, page, ...props }) => (
  <Title {...props}>
    <FormattedMessage
      id={`${page}-${titleFor}-title`}
      description={`Title for ${title}`}
      defaultMessage={`${title}`}
    />
  </Title>
);

CollectionsTitle.propTypes = {
  page: PropTypes.string.isRequired,
  titleFor: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default CollectionsTitle;
