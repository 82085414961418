import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
// Import Shared Components
import { IssuerStatement, Footer, ErrorBoundary } from 'shared-components';
// Import Layouts
import Base from '../layouts/Base';
import Body from '../layouts/Body';
import Header from '../layouts/Header';
import NotFound from '../layouts/NotFound';
import Shell from '../layouts/Shell';
// Import Route Components
import Redeem from './Redeem';

import { errorLogger } from '../utils/errors';

export default props => {
  const [chatURL, setChatURL] = useState(null);
  const [faqURL, setFaqURL] = useState(null);
  const [tollFreePhone, setTollFreePhone] = useState(null);
  const [termsAndConditions, setTermsAndConditions] = useState(null);

  return (
    <Shell>
      <Base>
        <Header {...props} />
        <Body chatURL={chatURL} faqURL={faqURL} tollFreePhone={tollFreePhone}>
          <ErrorBoundary errorLogger={errorLogger}>
            <Switch>
              <Route
                path="/payment/:redemptionToken"
                render={routeProps => (
                  <Redeem
                    setTollFreePhone={setTollFreePhone}
                    setChatURL={setChatURL}
                    setFaqURL={setFaqURL}
                    setTermsAndConditions={setTermsAndConditions}
                    {...routeProps}
                  />
                )}
              />
              <Route component={NotFound} />
            </Switch>
          </ErrorBoundary>
        </Body>
        {termsAndConditions && (
          <Footer
            legalStatements={[
              <IssuerStatement>
                {termsAndConditions}
              </IssuerStatement>,
            ]}
          />
        )}
      </Base>
    </Shell>
  );
};
