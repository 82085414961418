import styled from 'styled-components';

export default styled.li`
  color: ${({ theme }) => theme.brand.text};
  justify-content: space-between;

  span:first-child {
    text-align: left;
  }

  span:last-child {
    font-weight: bold
  }
`;
