import styled from 'styled-components';

export const TitleContainer = styled.div`
  h1 {
    margin-bottom: 5px;
  }
  margin-bottom: 40px;
  text-align: center;
`;

export const Sections = styled.div`
  display: flex
  flex-direction: column;
  flex-grow: 1;

  & > *:first-child {
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.media.tabletLandscapeUp`
    flex-direction: row;

    & > *:first-child {
      margin-right: 30px;
      margin-bottom: 0;
    }
  `}
`;

export const Section = styled.section`
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }

`;
