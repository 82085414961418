import React from 'react';
// Import Static files
import MC from '../../static/mc_deb.png';
import VISA from '../../static/visa-debit.png';
// import LOCK from '../../static/card-lock.png';
import LOCK from '../../static/lock.png';
// Import Styles
import { CardIcon, CardIconGroup } from './styles';

export default () => (
  <CardIconGroup>
    <CardIcon src={VISA} alt="Visa debit card" />
    <CardIcon src={MC} alt="Master card debit" />
    <CardIcon src={LOCK} alt="Secure lock" />
  </CardIconGroup>
);
