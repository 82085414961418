import React from 'react';
import { FormattedMessage } from 'react-intl';
// Import Shared Components
import { Label } from 'shared-components';
import PropTypes from 'prop-types';

const CollectionsLabel = ({ labelFor, label, page }) => (
  <Label htmlFor={labelFor}>
    <FormattedMessage
      id={`${page}-${labelFor}-label`}
      description={`Label for ${label}`}
      defaultMessage={`${label}`}
    />
  </Label>
);

CollectionsLabel.propTypes = {
  page: PropTypes.string.isRequired,
  labelFor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
  
};

export default CollectionsLabel;
