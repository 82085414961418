import React from 'react';
import PropTypes from 'prop-types';
// Import Components
import ExternalLink from '../../components/ExternalLink';
// Import Component Styles
import { HelpSection, HelpItems } from './styles';

const Help = ({ chatURL, faqURL, tollFreePhone }) => (
  <HelpSection>
    <HelpItems>
      <p>Need Help?</p>
      {faqURL && (
        <ExternalLink href={faqURL} page="redeem" linkFor="FAQ" link="FAQ" />
      )}
      {chatURL && (
        <ExternalLink
          href={chatURL}
          page="redeem"
          linkFor="phone"
          link="Chat With Us"
        />
      )}
      {tollFreePhone && (
        <ExternalLink
          href={`tel:${tollFreePhone}`}
          page="redeem"
          linkFor="phone"
          link={tollFreePhone}
        />
      )}
    </HelpItems>
  </HelpSection>
);

Help.propTypes = {
  chatURL: PropTypes.string,
  faqURL: PropTypes.string,
  tollFreePhone: PropTypes.string,
};

Help.defaultProps = {
  faqURL: null,
  chatURL: null,
  tollFreePhone: null,
};

export default Help;
