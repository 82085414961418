import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import List from './styles';

const ListItem = ({ page, list, listFor, itemNum, value, ...props }) => (
  <List>
    <span>
      <FormattedMessage
        id={`${page}-${listFor}-item${itemNum}`}
        description={`List for ${listFor}`}
        defaultMessage={list}
        {...props}
      />
    </span>
    <span>{value}</span>
  </List>
);

ListItem.propTypes = {
  list: PropTypes.string.isRequired,
  listFor: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  itemNum: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default ListItem;
