import React from 'react';
// Import Global Components
import PropTypes from 'prop-types';
import Title from '../Title';
import Text from '../Text';
import Alert from '../Alert';
// Import Layouts
import MainLayout from '../../layouts/Main';
// Import Component Styles
import { Sections, TitleContainer, Section } from './styles';

const Collections = ({ title, subTitle, page, error, sectionOne, sectionTwo }) => (
  <MainLayout>
    {subTitle ? (
      <TitleContainer>
        <Title as="h1" title={title} titleFor="main" page={page}>
          {title}
        </Title>
        <Text text={subTitle} textFor="main" page={page} />
      </TitleContainer>
    ) : (
      <Title title={title} titleFor="main" page={page}>
        {title}
      </Title>
    )}
    {error && (
      <Alert type="danger" alert={error} alertFor="form-error" page={page} />
    )}
    <Sections>
      <Section>{sectionOne}</Section>
      <Section>{sectionTwo}</Section>
    </Sections>
  </MainLayout>
);

Collections.propTypes = {
  page: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  error: PropTypes.string,
  sectionOne: PropTypes.element.isRequired,
  sectionTwo: PropTypes.element.isRequired
};

Collections.defaultProps = {
  error: null
};

export default Collections;
