import basicTheme from '../basicTheme';
import {
  DEV_SUBDOMAIN_PREFIX,
  STAGING_SUBDOMAIN_PREFIX,
  DEV_APEX_DOMAIN,
  STAGING_APEX_DOMAIN
} from '../constants/appEnv';
import { DEFAULT_THEME_SUBDOMAIN } from '../constants/whiteLabel';

// getSubdomain
// We are expecting the hostname to have the following format
// company-customer-subdomain(s).(potentially an environment subdomain).hostname.com
export const getSubdomain = hostname => {
  // If we are running on localhost then we should use the default color scheme
  if (hostname === 'localhost') {
    return DEFAULT_THEME_SUBDOMAIN;
  }

  // If not on localhost then we can inspect the hostname
  const hostnameParts = hostname.split('.');

  // This is for when testing locally with a subdomain on localhost
  if (hostname.includes('localhost')) {
    return hostnameParts.splice(0, hostnameParts.length - 1).join('.');
  }

  if (hostnameParts.length <= 2) {
    // hostname contains only a domain name and a top level domain
    // so we should return the default unbranded color scheme
    // this would correspond to the default production site
    return DEFAULT_THEME_SUBDOMAIN;
  }

  if (
    hostnameParts.length === 3 &&
    (hostnameParts[0] === DEV_SUBDOMAIN_PREFIX ||
      hostnameParts[0] === STAGING_SUBDOMAIN_PREFIX ||
      hostnameParts[1] === DEV_APEX_DOMAIN ||
      hostnameParts[1] === STAGING_APEX_DOMAIN)
  ) {
    // hostname contains a single subdomain, a domain name and a top level domain
    // the single subdomain also matches an environment value
    // therefore we should return the unbranded color scheme
    // for example dev.app-domain.io, staging.app-domain.io
    return DEFAULT_THEME_SUBDOMAIN;
  }

  // hostname contains at least one subdomain that is not only just a single environment prefix ('dev' or 'staging'),
  // a domain name and a top level domain
  // we can grab all the subdomains and treat them as a single value
  // slice off everything but the domain and top level domain and join them together
  return hostnameParts.splice(0, hostnameParts.length - 2).join('.');
};

// redirectToHostname
// This will remove the current subdomain and redirect to the bare hostname
export const redirectToHostname = () => {
  const { protocol, pathname, hostname, port } = window.location;

  if (hostname === 'localhost') {
    return 'There was an error retrieving data from the server';
  }

  // If not on localhost then we can inspect the hostname
  const hostnameParts = hostname.split('.');

  if (hostnameParts.length === 2) {
    // there is no subdomain and we where unable to retrieve the default theme we should return an error
    return 'There was an error retrieving data from the server';
  }

  if (
    hostnameParts.length === 3 &&
    (hostnameParts[0] === DEV_SUBDOMAIN_PREFIX ||
      hostnameParts[0] === STAGING_SUBDOMAIN_PREFIX ||
      hostnameParts[1] === DEV_APEX_DOMAIN ||
      hostnameParts[1] === STAGING_APEX_DOMAIN)
  ) {
    // In old dev setup: there is a single subdomain that is an environment prefix and we where unable to retrieve the default theme we should return an error
    // hostname is in correct format subdomain.host.rootdomain
    // so we can redirect to just hostname and rootdomain
    
    // In new dev setup: we no longer use environment prefixes. Our subdomains are now application names - virtualpaycard, payhubportal, etc.
    // Environment types are included in our domain. If it's a single subdomain then it's the app name and if it's a while label then
    // the second subdomain would be the white label prefix (sub1.sub2.dev-berkeleypayment.io) e.g. pungle.payaccount.dev-berkeleypayment.io
    return 'There was an error retrieving data from the server';
  }

  if (
    hostnameParts.length >= 4 &&
    (hostnameParts[hostnameParts.length - 3] === DEV_SUBDOMAIN_PREFIX ||
      hostnameParts[hostnameParts.length - 3] === STAGING_SUBDOMAIN_PREFIX)
  ) {
    // there is 2+ subdomains and the first subdomain is an environment prefix and we where unable
    // to retrieve the custom theme, we should return an error
    // and redirect to the hostname with only the environment prefix
    const newHostname = hostnameParts.splice(-3, 3).join('.');
    if (port) {
      window.location.replace(`${protocol}//${newHostname}:${port}${pathname}`);
    } else {
      window.location.replace(`${protocol}//${newHostname}${pathname}`);
    }
    return 'There was an error retrieving data from the server';
  }

  if (hostnameParts.length >= 3) {
    // This means the there exists at least one subdomain and it is not only an environmental prefix
    // so we can just redirect to the domain name and the top level domain
    const newHostname = hostnameParts.splice(-2, 2).join('.');
    if (port) {
      window.location.replace(`${protocol}//${newHostname}:${port}${pathname}`);
    } else {
      window.location.replace(`${protocol}//${newHostname}${pathname}`);
    }

    return 'There was an error retrieving data from the server';
  }

  return 'There was an error retrieving data from the server';
};

function hexToRgba(hex, opacity) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(
      result[3],
      16
    )}, ${opacity})`
    : null;
}

export const injectTheme = brand => {
  const primaryLight = hexToRgba(brand.primary, 0.15);
  const bodyBorder = hexToRgba(brand.tertiary, 0.8);

  // Define button styles for theme that include brand colors
  const textButtons = {
    primary: `
    background-color: transparent;
    border-color: transparent;
    border-style: solid;
    color: ${brand.primary};
  `,
    secondary: `
    color: ${brand.secondary};
    background-color: transparent;
    border-color: transparent;
    border-style: solid;
  `,
  };
  const defaultButtons = {
    primary: `
    border-style: solid;
    color: ${brand.primary_text};
    background-color: ${brand.primary};
    border-color: ${brand.primary};`,
    secondary: `
    border-style: solid;
    color: ${brand.secondary_text};
    background-color: ${brand.secondary};
    border-color: ${brand.secondary};`,
  };

  // Define sidebar navigation in client portal styles that include brand colors
  const sidebarStyles = {
    open: `
    color: ${brand.primary};
    background-color: ${basicTheme.colors.grey};
    font-weight: bold;
    padding-left: 15px;
  `,
    hover: `
    color: ${brand.primary};
    background-color: ${basicTheme.colors.grey};
    font-weight: bold;
  `,
    default: `
    color: ${brand.text};
    background-color: #fff;
    font-weight: normal;
  `,
  };

  const theme = {
    brand: {
      footerText: brand.footer_text,
      headerBackground: brand.header_background,
      headerText: brand.header_text,
      primaryText: brand.primary_text,
      secondaryText: brand.secondary_text,
      virtualCardText: brand.virtual_card_text,
      virtualCardArt: brand.virtual_card_art,
      virtualCardTerms: (brand.virtual_card_terms !== '' ? brand.virtual_card_terms : ''),
      logo: brand.logo,
      logo_fr: (brand.logo_fr !== '' ? brand.logo_fr : brand.logo),
      logo_redirect_url: brand.logo_redirect_url,
      favicon: brand.favicon,
      background: brand.background,
      title: brand.title,
      text: brand.text,
      primary: brand.primary,
      secondary: brand.secondary,
      tertiary: brand.tertiary,
      primaryLight,
      bodyBorder,
      activateCardholderAgreementEn: brand.activate_cardholder_agreement_en,
      activateCardholderAgreementFr: brand.activate_cardholder_agreement_fr,
      activatePrivacyPolicyEn: brand.activate_privacy_policy_en,
      activatePrivacyPolicyFr: brand.activate_privacy_policy_fr,
      activateElectronicComConsentEn: brand.activate_electronic_com_consent_en,
      activateElectronicComConsentFr: brand.activate_electronic_com_consent_fr,
      registerTermsOfUseEn: brand.register_terms_of_use_en,
      registerTermsOfUseFr: brand.register_terms_of_use_fr,
    },
    ...basicTheme,
    sidebar: {
      ...sidebarStyles,
    },
    buttons: {
      ...basicTheme.buttons,
      text: {
        ...textButtons,
        ...basicTheme.buttons.text,
      },
      default: {
        ...defaultButtons,
        ...basicTheme.buttons.default,
      },
    },
  };

  return theme;
};

export const isHRB = () => {
  const { hostname } = window.location;

  const subdomain = getSubdomain(hostname);

  return subdomain === 'hrblockadvantagecard';
};

export const getCardholderSupportEmail = () => {
  const isHRBClient = isHRB();

  if (isHRBClient) {
    return 'hrblockadvantagecard@mycardholdersupport.com';
  }

  return 'help@mycardholdersupport.com';
};