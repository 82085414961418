import React, { useRef } from 'react';
import { injectIntl } from 'react-intl';
// Import Shared Components
import { SubmitButton } from 'shared-components';
// Import Global Components
import Label from '../../../../components/Label';
import Legend from '../../../../components/Legend';
// Import Component Styles
import { Form, FormGroup, Input, InputGroup, InputContainer } from './styles';
// Import Translations
import { redeemEn } from '../../../../i18n/redeem';

// **TODO We should be using the shared components for inputs and labels**
export default injectIntl(
  ({ intl, handleFormSubmission, collectionAmount }) => {
    const firstNameInput = useRef(null);
    const lastNameInput = useRef(null);
    const postalCodeInput = useRef(null);
    const cardNumberInput = useRef(null);
    const expInput = useRef(null);
    const cvvInput = useRef(null);
    const amountInput = useRef(null);

    const handleOnSubmit = e => {
      e.preventDefault();

      const paymentData = {
        cardNumber: cardNumberInput.current.value,
        exp: expInput.current.value,
        firstName: firstNameInput.current.value,
        lastName: lastNameInput.current.value,
        postalCode: postalCodeInput.current.value,
        cvv: cvvInput.current.value,
        amount: amountInput.current.value,
      };

      handleFormSubmission(paymentData);
    };

    return (
      <Form id="redeem" onSubmit={handleOnSubmit}>
        <FormGroup form="redeem">
          <Legend
            legend={redeemEn['redeem-cardInformation-legend']}
            legendFor="cardInformation"
            page="redeem"
          />
          <InputGroup>
            <InputContainer>
              <Label
                label={redeemEn['redeem-firstName-label']}
                labelFor="firstName"
                page="redeem"
              />
              <Input name="firstName" required ref={firstNameInput} />
            </InputContainer>
            <InputContainer>
              <Label
                label={redeemEn['redeem-lastName-label']}
                labelFor="lastName"
                page="redeem"
              />
              <Input name="lastName" required ref={lastNameInput} />
            </InputContainer>
          </InputGroup>
          <InputGroup>
            <InputContainer>
              <Label
                label={redeemEn['redeem-amount-label']}
                labelFor="amount"
                page="redeem"
              />
              <Input
                name="amount"
                type="number"
                min="0"
                max={collectionAmount}
                title="Please enter an amount less that or equal to the full Payment Amount"
                step="0.01"
                required
                ref={amountInput}
              />
            </InputContainer>
          </InputGroup>
          <InputGroup>
            <InputContainer>
              <Label
                label={redeemEn['redeem-cardNumber-label']}
                labelFor="cardNumber"
                page="redeem"
              />
              <Input
                name="cardNumber"
                pattern="[0-9]+"
                title="Please enter digits only"
                required
                ref={cardNumberInput}
              />
            </InputContainer>
          </InputGroup>
          <InputGroup>
            <InputContainer>
              <Label
                label={redeemEn['redeem-cvv-label']}
                labelFor="cvv"
                page="redeem"
              />
              <Input
                name="cvv"
                required
                pattern="[0-9]{3}"
                title="Please enter 3 Digit CVV"
                ref={cvvInput}
              />
            </InputContainer>
            <InputContainer>
              <Label
                label={redeemEn['redeem-exp-label']}
                labelFor="exp"
                page="redeem"
              />
              <Input
                name="exp"
                pattern="[0-9]{4}"
                title="Please enter 4 Digits"
                required
                ref={expInput}
              />
            </InputContainer>
            <InputContainer>
              <Label
                label={redeemEn['redeem-postalCode-label']}
                labelFor="postalCode"
                page="redeem"
              />
              <Input name="postalCode" required ref={postalCodeInput} />
            </InputContainer>
          </InputGroup>
        </FormGroup>
        <SubmitButton
          buttonType="primary"
          size="large"
          value={intl.formatMessage({
            id: 'redeem-submit-button',
            defaultMessage: redeemEn['redeem-submit-button'],
          })}
        />
      </Form>
    );
  }
);
