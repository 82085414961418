import styled from 'styled-components';
import { Card } from 'shared-components';

export const RedeemMessage = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 40px;
  flex: 1;
  align-items: center;
  text-align: center;

  & > *:not(:last-child):not(:first-child) {
    margin-bottom: 30px;
  }
`;

export const SectionWrapper = styled.div`
  display: flex
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  & > *:first-child {
    margin-bottom: 30px;

    ul > li > span:first-child {
      width: 73px;
    }
  }

  & > *:last-child {
    ul > li > span:first-child {
      width: 146px;
    }
  }

  ${({ theme }) => theme.media.tabletLandscapeUp`
    flex-direction: row;
    align-items: start;

    & > *:first-child {
      margin-right: 60px;
      margin-bottom: 0;
    }
  `}
`;

export const Section = styled.div`
  /* display: flex; */
  width: 100%;
  text-align: left;
  /* flex-direction: column; */
  /* align-items: start; */

  & > *:not(:last-child) {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.media.tabletPortraitUp`
    width: 250px;
    max-width: 300px;
  `}
`;


export const DetailsList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex-grow: 1;
  width: 100%;

  & > li {
    display: flex;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

`;
