import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
// Import Global Components
import { ChangeLocale } from 'shared-components';
// Import Component Styles
import { StyledHeader } from './styles';

const Header = injectIntl(({ localization, intl, ...props }) => (
  <StyledHeader>
    {localization && <ChangeLocale {...props} messages={intl.messages} />}
  </StyledHeader>
));

Header.propTypes = {
  localization: PropTypes.bool.isRequired,
};

export default Header;
