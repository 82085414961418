import styled from 'styled-components';

export const CardIconGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const CardIcon = styled.img`
  height: 40px;
  padding: 0 10px;
`;
