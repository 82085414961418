import TRANSLATIONS from 'shared-components/src/i18n/translations';
import { redeemEn, redeemFr } from './redeem';

export default {
  en: {
    'header-en-button': 'EN',
    'header-fr-button': 'FR',
    'main-not-found-title': 'Sorry! This page could not be found.',
    ...redeemEn,
    ...TRANSLATIONS.en,
  },
  fr: {
    'header-en-button': 'EN',
    'header-fr-button': 'FR',
    'main-not-found-title': 'Sorry! This page could not be found.',
    ...redeemFr,
    ...TRANSLATIONS.fr,
  },
};
