import React from 'react';
// Import Global Components
import Title from '../../../components/Title';
import Text from '../../../components/Text';
// Import Layouts
import Main from '../../../layouts/Main';
// Import Translations
import { redeemEn } from '../../../i18n/redeem';

export default () => (
  <Main>
    <Title
      title={redeemEn['redeem-invalidLink-title']}
      titleFor="invalidLink"
      page="redeem"
    />
    <Text
      text={redeemEn['redeem-invalidLink-text']}
      textFor="invalidLink"
      page="redeem"
    />
  </Main>
);
