import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
`;

export const FormGroup = styled.fieldset`
  margin-bottom: 15px;
`;

export const Input = styled.input`
  color: #555;
  -webkit-appearance: none;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  line-height: 1.428571429;
  font-weight: normal;
  outline: none;
  padding: 6px 12px;
  border-radius: 4px;
  width: 100%;
  height: auto;
  min-height: 34px;
  background-color: #fff;
  border: ${({ theme }) => theme.borders.default};
  transition: border-color ease-in-out 0.15s, box-shadow eas-in-out 0.15s;
  margin: 0;
  box-sizing: border-box;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 10px;
  }

  ${({ theme }) => theme.media.desktopUp`
    flex-direction: row;

    & > *:not(:first-child) {
      margin-left: 10px;
    }
  `}
`;

export const InputContainer = styled.div`
  flex: 1;
`;
