import styled from 'styled-components';

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > *:first-child {
    margin-bottom: 30px;
    flex-grow: 1;

    p {
      margin-bottom: 30px;
    }
  }
`;

export const AccountInfoTitle = styled.dt`
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 15px;
`;
