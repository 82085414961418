import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ExternalLink } from 'shared-components';

const CollectionsExternalLink = ({linkFor, link, page, values, ...props}) => (
  <ExternalLink {...props}>
    <FormattedMessage
      id={`${page}-${linkFor}-link`}
      description={`Link for ${link}`}
      defaultMessage={`${link}`}
      values={values}
    />
  </ExternalLink>
);

CollectionsExternalLink.propTypes = {
  page: PropTypes.string.isRequired,
  linkFor: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  values: PropTypes.objectOf(PropTypes.node)
};

CollectionsExternalLink.defaultProps = {
  values: {}
};

export default CollectionsExternalLink;
